import React from "react";
import { Flex, Box, Button, Text } from "theme-ui";
import { Link } from "./Link";
import xdoLogo from "../components/Assets/logo-xdo-white.svg";
import xusdLogo from "../components/Assets/logo-xusd-white.svg";
import metamaskLogo from "../components/Assets/logo-metamask.svg";
import { Icon } from "./Icon";
import { addXdoToken, addXusdToken } from "../hooks/AddToMetamask";
import useXdoXusdPrices from "../hooks/useXdoXusdPrices";

export const Nav: React.FC = () => {
  const prices = useXdoXusdPrices();
  return (
    <Box as="nav" sx={{ display: ["none", "flex"], alignItems: "center", flex: 1 }}>
      <Flex>
        <Link to="/">Home</Link>
        <Link to="/farm">Farm</Link>
        {/* <Link to="/bridge">xBridge</Link> */}
      </Flex>
      <Flex sx={{ justifyContent: "flex-end", mr: 3, flex: 1 }}>
        <Flex>
          <Link sx={{ fontSize: 1, lineHeight: "30px" }} to="/risky-troves">
            Liquidate
          </Link>
          <Link sx={{ fontSize: 1, lineHeight: "30px" }} to="/redemption">
            Redeem
          </Link>
        </Flex>
        <Box sx={{ display: ["none", "flex"] }}>
          <Flex sx={{ alignItems: "center" }}>
            <Flex
              variant="layout.badge"
              sx={{
                ml: 3,
                mr: 4,
                flexDirection: "row",
                paddingTop: 0,
                paddingBottom: 0,
                marginRight: "6px"
              }}
            >
              <Button
                variant="icon"
                onClick={() =>
                  window.open(
                    "https://app.pangolin.exchange/#/swap?outputCurrency=0x9eF758aC000a354479e538B8b2f01b917b8e89e7",
                    "_blank"
                  )
                }
                sx={{ float: "left" }}
              >
                <img src={xdoLogo} alt="xdo logo" style={{ maxHeight: "20px" }} />
              </Button>
              <Button
                variant="linkButton"
                onClick={() =>
                  window.open(
                    "https://app.pangolin.exchange/#/swap?outputCurrency=0x9eF758aC000a354479e538B8b2f01b917b8e89e7",
                    "_blank"
                  )
                }
                sx={{ float: "left" }}
              >
                <Text sx={{ lineHeight: "40px", marginRight: "4px" }}>${prices.xdoPrice}</Text>
                <Icon name="external-link-square-alt" size={"1x"} />
              </Button>
              <Button variant="icon" onClick={addXdoToken} sx={{ float: "left" }}>
                <img src={metamaskLogo} alt="metamask logo" style={{ maxHeight: "30px" }} />
              </Button>
            </Flex>
          </Flex>

          <Flex sx={{ alignItems: "center" }}>
            <Flex
              variant="layout.badge"
              sx={{
                ml: 3,
                mr: 4,
                flexDirection: "row",
                paddingTop: 0,
                paddingBottom: 0,
                marginRight: "6px"
              }}
            >
              <Button
                variant="icon"
                onClick={() =>
                  window.open(
                    "https://app.pangolin.exchange/#/swap?outputCurrency=0x3509f19581aFEDEff07c53592bc0Ca84e4855475",
                    "_blank"
                  )
                }
                sx={{ float: "left" }}
              >
                <img src={xusdLogo} alt="xusd logo" style={{ maxHeight: "22px" }} />
              </Button>
              <Button
                variant="linkButton"
                onClick={() =>
                  window.open(
                    "https://app.pangolin.exchange/#/swap?outputCurrency=0x3509f19581aFEDEff07c53592bc0Ca84e4855475",
                    "_blank"
                  )
                }
                sx={{ float: "left" }}
              >
                <Text sx={{ lineHeight: "40px", marginRight: "4px" }}>${prices.xusdPrice}</Text>
                <Icon name="external-link-square-alt" size={"1x"} />
              </Button>
              <Button variant="icon" onClick={addXusdToken} sx={{ float: "left" }}>
                <img src={metamaskLogo} alt="metamask logo" style={{ maxHeight: "30px" }} />
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
