import { Decimal } from "@liquity/lib-base";

type UniswapResponse = {
  data?: {
    bundle: {
      ethPrice: string;
    } | null;
    pair: {
      token1Price: string;
      reserveUSD: string;
      totalSupply: string;
    } | null;
    token: {
      derivedETH: string;
    } | null;
  };
  errors?: Array<{ message: string }>;
};

type UniswapPairResponse = {
  data?: {
    pair: {
      token0Price: string;
      reserveUSD: string;
      totalSupply: string;
    } | null;
  };
  errors?: Array<{ message: string }>;
};

const uniswapQuery = () => `{
  token(id: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664") {
    derivedETH
  },
  bundle(id: 1) {
    ethPrice
  },
  pair(id: "0x4a6993d6bd24993f131f8ac2ac0b0fe0e6e71713") {
    token1Price
    totalSupply
    reserveUSD
  }
}`;

const uniswapPairQuery = (uniTokenAddress: string) => `{
  pair(id: "${uniTokenAddress.toLowerCase()}") {
    token0Price
    totalSupply
    reserveUSD
  }
}`;

export async function fetchPrices(uniTokenAddress: string) {
  // First figuring out the XDO price.
  const xdoResponse = await window.fetch("https://api.thegraph.com/subgraphs/name/pangolindex/exchange", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: uniswapPairQuery(uniTokenAddress),
      variables: null
    })
  });

  const xUSDResponse = await window.fetch("https://api.thegraph.com/subgraphs/name/pangolindex/exchange", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: uniswapQuery(),
      variables: null
    })
  });

  if (!xdoResponse.ok || !xUSDResponse.ok) {
    return Promise.reject("Network error connecting to Pangolin subgraph");
  }

  const xdoResponseJSON: UniswapPairResponse = await xdoResponse.json();
  const xUSDResponseJSON: UniswapResponse = await xUSDResponse.json();

  if (xdoResponseJSON.errors) {
    return Promise.reject(xdoResponseJSON.errors);
  }

  if (xUSDResponseJSON.errors) {
    return Promise.reject(xUSDResponseJSON.errors);
  }

  if (
    typeof xdoResponseJSON.data?.pair?.reserveUSD === "string" &&
    typeof xdoResponseJSON.data?.pair?.totalSupply === "string" &&
    typeof xdoResponseJSON.data?.pair?.token0Price === "string" &&
    typeof xUSDResponseJSON.data?.token?.derivedETH === "string" &&
    typeof xUSDResponseJSON.data?.pair?.reserveUSD === "string" &&
    typeof xUSDResponseJSON.data?.pair?.totalSupply === "string" &&
    typeof xUSDResponseJSON.data?.bundle?.ethPrice === "string" &&
    typeof xUSDResponseJSON.data?.pair?.token1Price === "string"
  ) {
    const ethPriceUSD = Decimal.from(xUSDResponseJSON.data.bundle.ethPrice);
    const lqtyPriceUSD = Decimal.from(xUSDResponseJSON.data.pair.token1Price).mul(ethPriceUSD);
    const uniLpPriceUSD = Decimal.from(xdoResponseJSON.data.pair.reserveUSD).div(
      Decimal.from(xdoResponseJSON.data.pair.totalSupply)
    );

    // console.log(`ethPriceUSD : ${ethPriceUSD}`);
    // console.log(`daiPriceUSD : ${daiPriceUSD}`);
    // console.log(`xdoPriceUSD : ${lqtyPriceUSD}`);
    // console.log(`uniLpPriceUSD : ${uniLpPriceUSD}`);

    return { lqtyPriceUSD, uniLpPriceUSD };
  }

  return Promise.reject("Sushiswap doesn't have the required data to calculate yield");
}