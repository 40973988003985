import React from "react";
import { Text, Flex, Box, Heading, Card, Paragraph } from "theme-ui";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN, GT } from "../strings";
import { useLiquity } from "../hooks/LiquityContext";
import { shortenAddress } from "../utils/shortenAddress";
import { InfoWalletIcon } from "./InfoWalletIcon";

const select = ({ accountBalance, lusdBalance, lqtyBalance }: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance
});

export const UserAccount: React.FC = () => {
  const { account } = useLiquity();
  const { accountBalance, lusdBalance, lqtyBalance } = useLiquitySelector(select);

  return (
    <Box sx={{ display: ["none", "flex"] }}>
      <Flex sx={{ alignItems: "center" }}>
        <Flex
          variant="layout.badge"
          sx={{
            ml: 3,
            mr: 4,
            flexDirection: "row",
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 2,
            marginLeft: "0px",
            height: "40px",
            lineHeight: "40px",
            width: "40px"
          }}
        >
          <InfoWalletIcon
            placement="bottom"
            size="lg"
            tooltip={
              <Card variant="tooltip" sx={{ minWidth: ["auto", "180px"] }}>
                <Paragraph>
                  <Text as="span" sx={{ fontSize: 1, marginLeft: "16px" }} title={account}>
                    {shortenAddress(account)}
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Flex sx={{ alignItems: "center" }}>
                    {([
                      ["AVAX", accountBalance],
                      [COIN, lusdBalance],
                      [GT, lqtyBalance]
                    ] as const).map(([currency, balance], i) => (
                      <Flex key={i} sx={{ ml: 3, flexDirection: "column" }}>
                        <Heading sx={{ fontSize: 1 }}>{currency}</Heading>
                        <Text sx={{ fontSize: 1 }}>{balance.prettify()}</Text>
                      </Flex>
                    ))}
                  </Flex>
                </Paragraph>
              </Card>
            }
          ></InfoWalletIcon>
        </Flex>
      </Flex>
    </Box>
  );
};
