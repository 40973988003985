type GraphResponse = {
  data?: {
    bundle: {
      ethPrice: string;
    } | null;
    xdoavax: {
      token1Price: string;
      reserveUSD: string;
      totalSupply: string;
    } | null;
    xdodai: {
      token1Price: string;
      reserveUSD: string;
      totalSupply: string;
    } | null;
    token: {
      derivedETH: string;
    } | null;
  };
  errors?: Array<{ message: string }>;
};

const xdoxusdQuery = () => `{
  token(id: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664") {
    derivedETH
  },
  bundle(id: 1) {
    ethPrice
  },
  xdoavax:pair(id: "0x4a6993d6bd24993f131f8ac2ac0b0fe0e6e71713") {
    token1Price
    totalSupply
    reserveUSD
  },
 xdodai:pair(id: "0x658c5af42433e2a75e8de9ee95dd49a0037c58d1") {
    token1Price
    totalSupply
    reserveUSD
  }
}`;

export async function fetchXdoXusdPrices() {
  // First figuring out the XDO price.
  const priceResponses = await window.fetch("https://api.thegraph.com/subgraphs/name/pangolindex/exchange", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: xdoxusdQuery(),
      variables: null
    })
  });

  if (!priceResponses.ok) {
    return Promise.reject("Network error connecting to Pangolin subgraph");
  }

  const priceResponseJSON: GraphResponse = await priceResponses.json();

  if (priceResponseJSON.errors) {
    return Promise.reject(priceResponseJSON.errors);
  }

  if (
    typeof priceResponseJSON.data?.xdodai?.reserveUSD === "string" &&
    typeof priceResponseJSON.data?.xdodai?.totalSupply === "string" &&
    typeof priceResponseJSON.data?.xdodai?.token1Price === "string" &&
    typeof priceResponseJSON.data?.xdoavax?.reserveUSD === "string" &&
    typeof priceResponseJSON.data?.xdoavax?.totalSupply === "string" &&
    typeof priceResponseJSON.data?.xdoavax?.token1Price === "string" &&
    typeof priceResponseJSON.data?.token?.derivedETH === "string" &&
    typeof priceResponseJSON.data?.bundle?.ethPrice === "string" 
  ) {
    const avaxPrice = parseFloat(priceResponseJSON.data.bundle.ethPrice);
    const xusdAvaxPrice = parseFloat(priceResponseJSON.data.token.derivedETH);
    const xusdPrice = parseFloat((avaxPrice * xusdAvaxPrice).toFixed(6)).toFixed(2);
    const xdouavaxSupply = parseFloat(priceResponseJSON.data.xdoavax.totalSupply);
    const xdodaiSupply = parseFloat(priceResponseJSON.data.xdodai.totalSupply);
    let xdoPrice: string = "0";
    if (xdouavaxSupply >= xdodaiSupply) {
      const xdoavax = parseFloat(priceResponseJSON.data.xdoavax.token1Price) * avaxPrice;
      xdoPrice = xdoavax.toFixed(2);
    } else {
      xdoPrice = parseFloat(priceResponseJSON.data.xdodai.token1Price).toFixed(2);
    }
    return { xusdPrice, xdoPrice };
  }

  return Promise.reject("The Graph doesn't have the required data to calculate prices");
}