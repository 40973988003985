declare const window: any;
const addXdoToken = () =>
  addToken({
    type: "ERC20",
    options: {
      address: "0x3509f19581afedeff07c53592bc0ca84e4855475",
      symbol: "XDO",
      decimals: 18,
      image: "https://assets.coingecko.com/coins/images/16289/small/logo-web-transparent_2.png"
    }
  });

const addXusdToken = () =>
  addToken({
    type: "ERC20",
    options: {
      address: "0x9ef758ac000a354479e538b8b2f01b917b8e89e7",
      symbol: "XUSD",
      decimals: 18,
      image:
        "https://assets.coingecko.com/coins/images/16291/small/xUSD-web-transparent.png?1623661715"
    }
  });
const addToken = (params: any) =>
  window.ethereum
    .request({ method: "wallet_watchAsset", params })
    .then(console.log("Success, Token added!"))
    .catch((error: Error) => console.log(`Error: ${error.message}`));

export { addXdoToken, addXusdToken };
